interface ScrollToElementWithOffset {
  selector: string;
  extraOffset?: number;
}

export const scrollToElementWithOffset = ({
  selector,
  extraOffset = 0,
}: ScrollToElementWithOffset) => {
  const element = document.getElementById(selector);
  const offset = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      '--navbar-height'
    )
  );
  const elementPosition = element?.getBoundingClientRect().top;

  if (elementPosition) {
    const offsetPosition = elementPosition - offset - extraOffset;

    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};
