import React from 'react';
import './MenuIcon.scss';

interface MenuIconProps {
  toggleMobileMenu: () => void;
  isMobileMenuOpen: boolean;
}

const MenuIcon: React.FC<MenuIconProps> = ({
  isMobileMenuOpen,
  toggleMobileMenu,
}) => {
  return (
    <div className='menu-icon' onClick={toggleMobileMenu}>
      <div className={`hamburger ${isMobileMenuOpen ? 'open' : ''}`}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default MenuIcon;
