import React, { useEffect, useState } from 'react';

import Club from '../components/Club/Club';
import DocumentList from '../components/DocumentList/DocumentList';
import Layout from '../components/Layout/Layout';
import Subheader from '../components/Subheader/Subheader';
import { NavItemId } from '../data/navbarData';

const Resident: React.FC = () => {
  const [visibleSection, setVisibleSection] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = [NavItemId.KLUB_KUBUS, NavItemId.AKTY_PRAWNE_DOKUMENTY];
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          );
        }
        return false;
      });

      if (currentSection && currentSection !== visibleSection) {
        setVisibleSection(currentSection);
        window.history.pushState(null, '', `#${currentSection}`);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visibleSection]);

  return (
    <Layout>
      <div id={NavItemId.KLUB_KUBUS}>
        <Subheader title='Klub Kubuś' />
        <Club />
      </div>
      <div id={NavItemId.AKTY_PRAWNE_DOKUMENTY}>
        <Subheader title='Dokumenty - Akty Prawne' />
        <DocumentList categoryId={24} />
        <Subheader title='Regulaminy' />
        <DocumentList categoryId={25} />
      </div>
    </Layout>
  );
};

export default Resident;
