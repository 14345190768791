import React from 'react';
import './Submenu.scss';
import { NavItem, SubMenuItem } from '../../../../data/navbarData';
import Text from '../../../core/Text/Text';
import { useContrast } from '../../../../context/ContrastContext';
import classNames from 'classnames';

interface SubmenuProps {
  item: NavItem;
  isActive: boolean;
  openSubMenu: string | null;
  onSubmenuItemClick: (subitem: SubMenuItem) => void;
}

const Submenu: React.FC<SubmenuProps> = ({
  item,
  isActive,
  openSubMenu,
  onSubmenuItemClick,
}) => {
  const { highContrast } = useContrast();
  if (!item.subMenu) {
    return null;
  }

  return (
    <ul
      className={classNames('sub-menu', {
        open: openSubMenu === item.path,
        active: isActive,
        highContrast,
      })}
    >
      {item.subMenu.map((subItem, index) => (
        <li
          key={`${subItem.path}-${index}`}
          className={classNames('sub-menu-item', { highContrast })}
        >
          <a
            className='nav-content-submenu'
            target='_blank'
            rel='noreferrer'
            href={subItem.url ? subItem.url : undefined}
            onClick={() => onSubmenuItemClick(subItem)}
          >
            <Text className={classNames('submenu-label', { highContrast })}>
              {subItem.label}
            </Text>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Submenu;
