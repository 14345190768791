import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import './Info.scss';
import { useContrast } from '../../../context/ContrastContext';
import classNames from 'classnames';

const Info: React.FC<{ message: string }> = ({ message }) => {
  const { highContrast } = useContrast();

  return (
    <div className={classNames('info-container', { highContrast })}>
      <Icon
        icon={faInfoCircle}
        color={highContrast ? 'var(--spol-white)' : 'var(--spol-info)'}
      />
      <Text className='info-message'>{message}</Text>
    </div>
  );
};

export default Info;
