import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from './components/Logo/Logo';
import { NavItem, navItems, SubMenuItem } from '../../data/navbarData';
import classNames from 'classnames';

import './Navbar.scss';
import { scrollToElementWithOffset } from '../../utils/scrollToElementWithOffset';
import MenuIcon from './components/MenuIcon/MenuIcon';
import useMediaQuery from '../../hooks/useMediaQuery';
import NavElement from './components/NavElement/NavElement';
import { useContrast } from '../../context/ContrastContext';

interface NavbarProps {
  isMobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
}

const Navbar: React.FC<NavbarProps> = ({
  toggleMobileMenu,
  isMobileMenuOpen,
}) => {
  const navigate = useNavigate();
  const { highContrast } = useContrast();

  const isMobile = useMediaQuery('(max-width: 1001px)');

  const [scrolled, setScrolled] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);

  const handleScroll = useCallback(() => {
    if (!isMobile) {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  }, [isMobile]);

  const handleSubMenuClick = ({ path, id }: NavItem) => {
    if (path) {
      navigate(path);
      if (id) {
        setTimeout(() => {
          scrollToElementWithOffset({ selector: id });
        }, 250);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleOpenSubmenu = (path: string | null) => {
    setOpenSubMenu(path);
  };

  const onSubmenuItemClick = (subItem: SubMenuItem) => {
    toggleMobileMenu();
    handleOpenSubmenu(null);
    handleSubMenuClick(subItem);
  };

  return (
    <nav
      id='navbar'
      className={classNames('navbar', {
        scrolled,
        highContrast,
      })}
    >
      <div
        className={classNames('logo-wrapper', {
          'logo-centered': isMobileMenuOpen,
        })}
      >
        <Logo />
      </div>
      <div className='hamburger-wrapper'>
        <MenuIcon
          isMobileMenuOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
        />
      </div>
      <ul
        className={classNames('nav-menu', {
          active: isMobileMenuOpen,
          highContrast,
        })}
      >
        {navItems.map((item, index) => (
          <NavElement
            key={index}
            item={item}
            isMobileMenuOpen={isMobileMenuOpen}
            openSubMenu={openSubMenu}
            isMobile={isMobile}
            toggleMobileMenu={toggleMobileMenu}
            onSubmenuItemClick={onSubmenuItemClick}
            handleOpenSubmenu={handleOpenSubmenu}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
