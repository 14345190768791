import React from 'react';
import './WorkingHours.scss';
import Text from '../Text/Text';
import classNames from 'classnames';
import { useContrast } from '../../../context/ContrastContext';

interface WorkingHour {
  day: string;
  hours: string;
}

interface WorkingHoursProps {
  hours: WorkingHour[];
  title: string;
}

const WorkingHours: React.FC<WorkingHoursProps> = ({ hours, title }) => {
  const { highContrast } = useContrast();

  return (
    <div>
      <Text isBold>{title}</Text>
      <table className={classNames('working-hours', { highContrast })}>
        <tbody>
          {hours.map((hour, index) => (
            <tr key={index}>
              <td>
                <Text>{hour.day}</Text>
              </td>
              <td>
                <Text>{hour.hours}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WorkingHours;
