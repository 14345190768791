import React, { useEffect, useState } from 'react';

import CooperativeBodies from '../components/CooperativeBodies/CooperativeBodies';
import History from '../components/History/History';
import Layout from '../components/Layout/Layout';
import Posts from '../components/Posts/Posts';
import Subheader from '../components/Subheader/Subheader';
import { historyItems } from '../data/historyItems';
import { NavItemId } from '../data/navbarData';
import { addScrollEventListener } from '../utils/handleScrolll';

const About: React.FC = () => {
  const [visibleSection, setVisibleSection] = useState<string | null>(null);

  useEffect(() => {
    const sections = [
      NavItemId.NASZA_HISTORIA,
      NavItemId.ORGANY_SPOLDZIELNI,
      NavItemId.GALERIA,
    ];
    const removeEventListener = addScrollEventListener(
      sections,
      visibleSection,
      setVisibleSection
    );
    return removeEventListener;
  }, [visibleSection]);

  return (
    <Layout>
      <div id={NavItemId.NASZA_HISTORIA}>
        <Subheader title='Nasza historia' />
        <History items={historyItems} />
      </div>
      <div id={NavItemId.ORGANY_SPOLDZIELNI}>
        <Subheader title='Organy spółdzielni' />
        <CooperativeBodies />
      </div>
      <div id={NavItemId.GALERIA}>
        <Subheader title='Galeria' />
        <Posts categoryId={26} hasPagination />
      </div>
    </Layout>
  );
};

export default About;
