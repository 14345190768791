import React from 'react';
import classNames from 'classnames';
import hero7 from '../../assets/images/hero-7.jpg';
import hero3 from '../../assets/images/hero-3.jpg';
import hero4 from '../../assets/images/hero-4.jpg';
import hero5 from '../../assets/images/hero-5.jpg';
import hero6 from '../../assets/images/hero-6.jpg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './HeroImages.scss';
import { useContrast } from '../../context/ContrastContext';
import Text from '../core/Text/Text';
import Icon from '../core/Icon/Icon';
import { scrollToElementWithOffset } from '../../utils/scrollToElementWithOffset';

const HeroImages: React.FC = () => {
  const { highContrast } = useContrast();

  const onChevronClick = () => {
    scrollToElementWithOffset({ selector: 'news' });
  };
  return (
    <div
      className={classNames('hero-images-grid', {
        'high-contrast': highContrast,
      })}
    >
      <img src={hero7} alt='Hero 1' className='image-1' />
      <img src={hero5} alt='Hero 2' className='image-2' />
      <img src={hero4} alt='Hero 3' className='image-3' />
      <img src={hero6} alt='Hero 4' className='image-4' />
      <img src={hero3} alt='Hero 5' className='image-5' />

      <div className={classNames('hero-message', { highContrast })}>
        <div className='hero-message-texts'>
          <Text fontSize='2rem' isBold as='h1' className='hero-text main-text'>
            Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu
          </Text>
          <Text isBold as='h2' className='hero-text secondary-text'>
            Odkryj wyjątkową atmosferę naszego osiedla w kolorze niebieskim
          </Text>
          <div className='chevronBox' onClick={onChevronClick}>
            <Icon
              className={classNames('chevronDown', {
                highContrast,
              })}
              icon={faChevronDown}
              size='2x'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImages;
