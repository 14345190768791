export const handleScroll = (
  sections: string[],
  visibleSection: string | null,
  setVisibleSection: (section: string | null) => void
) => {
  const currentSection = sections.find((section) => {
    const element = document.getElementById(section);
    if (element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top <= window.innerHeight / 2 &&
        rect.bottom >= window.innerHeight / 2
      );
    }
    return false;
  });

  if (currentSection && currentSection !== visibleSection) {
    setVisibleSection(currentSection);
    window.history.pushState(null, '', `#${currentSection}`);
  }
};

export const addScrollEventListener = (
  sections: string[],
  visibleSection: string | null,
  setVisibleSection: (section: string | null) => void
) => {
  const handleScrollWithArgs = () =>
    handleScroll(sections, visibleSection, setVisibleSection);

  window.addEventListener('scroll', handleScrollWithArgs);
  return () => {
    window.removeEventListener('scroll', handleScrollWithArgs);
  };
};
