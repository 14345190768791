import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { useContrast } from '../../../context/ContrastContext';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

import './LinkButton.scss';

type LinkButtonProps = {
  to: string;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
  message: string;
  className?: string;
};

const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  variant = 'primary',
  message,
  onClick,
  className = '',
}) => {
  const { highContrast } = useContrast();

  return (
    <Link
      to={to}
      className={classNames('link-button', `${variant}`, {
        [className]: !!className,
        highContrast,
      })}
      onClick={() => (onClick ? onClick?.() : undefined)}
    >
      <Text
        className={classNames('link-button-text', `${variant}`, {
          highContrast,
        })}
      >
        {message}
      </Text>
      <Icon
        className={classNames('link-button-icon', `${variant}`, {
          highContrast,
        })}
        icon={faArrowRight}
      />
    </Link>
  );
};

export default LinkButton;
