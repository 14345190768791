import Highlight from '../components/Highlight/Highlight';
import React from 'react';
import {
  faBuilding,
  faHammer,
  faCheck,
  faUser,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import Icon from '../components/core/Icon/Icon';

import budowa from '../assets/images/budowa.jpeg';
import budowa2 from '../assets/images/budowa2.jpeg';
import map from '../assets/images/map.jpeg';
import mieszkancy from '../assets/images/mieszkancy.jpeg';
import mieszkancy2 from '../assets/images/mieszkancy2.jpeg';

export interface HistoryItem {
  icon: React.ReactNode;
  title: string;
  date: string;
  description: string;
  image?: string;
  bottom?: React.ReactNode;
}

export const historyItems: HistoryItem[] = [
  {
    icon: <Icon icon={faBuilding} />,
    title: 'Powstanie Spółdzielni',
    date: '26.06.1990',
    description:
      'Zgodnie z Uchwałą Nr 8 Zebrania Przedstawicieli Członków Spółdzielni "METALURG" Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu powstała w wyniku podziału Spółdzielni Mieszkaniowej "METALURG" w Dąbrowie Górniczej. Uchwała o podziale Spółdzielni Mieszkaniowej "METALURG" w Dąbrowie Górniczej stanowiła wyodrębnienie zasobów mieszkaniowych zlokalizowanych w Olkuszu na Osiedlu "Słowiki" oraz przenosiła prawa i zobowiązania dotyczące osiedla na nowo powstającą Spółdzielnię Mieszkaniową „NOWA” w Olkuszu.',
    image: budowa,
    bottom: (
      <Highlight text='Osiedla "Słowiki" składające się z 22 budynków spółdzielczych z 944 mieszkaniami zostało wybudowane dla potrzeb Koksowni "Przyjaźń", stanowiącej część Kombinatu Metalurgicznego Huty Katowice. W 1995 roku Spółdzielnia przejęła 9 budynków funkcyjnych z 322 mieszkaniami od Zakładów Koksowniczych "Przyjaźń" w Dąbrowie Górniczej.' />
    ),
  },
  {
    icon: <Icon icon={faHammer} />,
    title: 'Budowa Osiedla',
    date: '1984-1989',
    description:
      'Budowa osiedla mieszkaniowego była realizowana etapami w latach 1984-1989. Równocześnie realizowana była infrastruktura towarzysząca tj. budowa szkoły podstawowej, przedszkola oraz żłobka.',
    image: budowa2,
  },
  {
    icon: <Icon icon={faCheck} />,
    title: 'Rejestracja Spółdzielni',
    date: '24.07.1990',
    description:
      'Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu została zarejestrowana w Sądzie Rejestrowym w Katowicach.',
    image: map,
  },
  {
    icon: <Icon icon={faUser} />,
    title: 'Pierwsi mieszkańcy',
    date: '1986',
    description:
      'Pierwsi mieszkańcy otrzymali klucze do mieszkań w budynku przy ul. Kosynierów 2 już w 1986 roku.',
    image: mieszkancy,
  },
  {
    icon: <Icon icon={faHome} />,
    title: 'Aktualny stan',
    date: 'Obecnie',
    description:
      'Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu posiada 31 budynków z 1301 mieszkaniami.',
    image: mieszkancy2,
    bottom: (
      <Highlight text='Zlokalizowanie Osiedla "Słowiki" w Olkuszu oprócz ciekawej architektonicznie zabudowy wyróżniającej się na tle istniejących budynków mieszkaniowych spowodowało zainwestowanie Huty Katowice w rozbudowę infrastruktury technicznej i społecznej w Olkuszu. Wraz z budową osiedla dokończono budowę Hali Widowiskowo-Sportowej, wybudowano przedszkole przy ul. Polnej, szkołę podstawową przy ul. Korczaka oraz rozbudowano stację uzdatniania wody i oczyszczalnię ścieków.' />
    ),
  },
];
