import React from 'react';
import classNames from 'classnames';

import Text from '../core/Text/Text';
import './Highlight.scss';
import { useContrast } from '../../context/ContrastContext';

interface HighlightProps {
  text: string;
}

const Highlight: React.FC<HighlightProps> = ({ text }) => {
  const { highContrast } = useContrast();

  return (
    <div className={classNames('highlight', { highContrast })}>
      <Text className='highlight-text'>{text}</Text>
    </div>
  );
};

export default Highlight;
