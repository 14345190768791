import React from 'react';

import HomeSecurityInfo from '../components/HomeSecurityInfo/HomeSecurityInfo';
import Layout from '../components/Layout/Layout';
import Subheader from '../components/Subheader/Subheader';

const Security: React.FC = () => {
  return (
    <Layout>
      <Subheader title='Stop włamaniom.' />
      <HomeSecurityInfo />
    </Layout>
  );
};

export default Security;
