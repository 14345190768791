import React from 'react';
import './Loader.scss';
import { useContrast } from '../../../context/ContrastContext';

interface LoaderProps {
  size?: number;
}

const Loader: React.FC<LoaderProps> = ({ size = 40 }) => {
  const { highContrast } = useContrast();
  const color = highContrast ? 'var(--spol-white)' : 'var(--spol-primary)';

  const loaderStyle = {
    width: size,
    height: size,
    borderColor: `${color} transparent transparent transparent`,
  };

  return <div className='loader' style={loaderStyle}></div>;
};

export default Loader;
