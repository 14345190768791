import React, { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './Layout.scss';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import Accessibility from './Accessibility/Accessibility';
import { useContrast } from '../../context/ContrastContext';
import classNames from 'classnames';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { highContrast } = useContrast();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <Navbar
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
      />
      <main className={classNames('site-content', { highContrast })}>
        {children}
      </main>
      <Footer />
      {!isMobileMenuOpen && (
        <>
          <ScrollToTop />
          <Accessibility />
        </>
      )}
    </div>
  );
};

export default Layout;
