import { Paths } from '../constants/paths';

export enum NavItemId {
  // Ogłoszenia
  AKTUALNOSCI = 'aktualnosci',
  PRZETARGI = 'przetargi',
  PRACA = 'praca',
  LOKALE = 'lokale',

  // O nas
  NASZA_HISTORIA = 'nasza-historia',
  ORGANY_SPOLDZIELNI = 'organy-spoldzielni',
  GALERIA = 'galeria',

  // Strefa Mieszkańca
  E_KARTOTEKA = 'e-kartoteka',
  KLUB_KUBUS = 'klub-kubus',
  AKTY_PRAWNE_DOKUMENTY = 'akty-prawne-dokumenty',
}

export interface SubMenuItem {
  path?: string;
  url?: string;
  label: string;
  id: NavItemId;
}

export interface NavItem {
  path?: string;
  label: string;
  subMenu?: SubMenuItem[];
  id?: string;
}

export const navItems: NavItem[] = [
  { path: Paths.HOME, label: 'Strona główna', subMenu: [] },
  {
    path: Paths.NEWS,
    label: 'Ogłoszenia',
    subMenu: [
      { path: Paths.NEWS, label: 'Aktualności', id: NavItemId.AKTUALNOSCI },
      { path: Paths.NEWS, label: 'Przetargi', id: NavItemId.PRZETARGI },
      { path: Paths.NEWS, label: 'Lokale użytkowe', id: NavItemId.LOKALE },
      { path: Paths.NEWS, label: 'Praca', id: NavItemId.PRACA },
    ],
  },
  {
    path: Paths.ABOUT,
    label: 'O nas',
    subMenu: [
      { path: Paths.ABOUT, label: 'Historia', id: NavItemId.NASZA_HISTORIA },
      {
        path: Paths.ABOUT,
        label: 'Organy spółdzielni',
        id: NavItemId.ORGANY_SPOLDZIELNI,
      },
      { path: Paths.ABOUT, label: 'Galeria', id: NavItemId.GALERIA },
    ],
  },
  { path: Paths.CONTACT, label: 'Kontakt', subMenu: [] },
  {
    path: Paths.RESIDENT,
    label: 'Strefa Mieszkańca',
    subMenu: [
      {
        url:
          'https://euslugi.softhard.com.pl/sm_nowa/Account/UserLogOn?ReturnUrl=/sm_nowa/',
        label: 'E-kartoteka',
        id: NavItemId.E_KARTOTEKA,
      },
      { path: Paths.RESIDENT, label: 'Klub Kubuś', id: NavItemId.KLUB_KUBUS },
      {
        path: Paths.RESIDENT,
        label: 'Dokumenty',
        id: NavItemId.AKTY_PRAWNE_DOKUMENTY,
      },
    ],
  },
  { path: Paths.EMERGENCY, label: 'Telefony alarmowe', subMenu: [] },
];
