import { faLeaf, faNewspaper, faRecycle, faTrash, faTshirt, faWineBottle } from '@fortawesome/free-solid-svg-icons';

export const wasteTypes = {
  metals: {
    icon: faRecycle,
    color: 'yellow',
    title: 'Metale i tworzywa sztuczne',
    items: [
      'odkręcone i zgniecione plastikowe butelki po napojach',
      'nakrętki',
      'plastikowe opakowania po produktach spożywczych',
      'opakowania wielomateriałowe (np. kartony po mleku i sokach)',
      'opakowania po środkach czystości (np. proszkach do prania), kosmetykach (np. szamponach, paście do zębów)',
      'plastikowe torby, worki, reklamówki, inne folie',
      'aluminiowe puszki po napojach i sokach',
      'puszki po konserwach',
      'folię aluminiową',
      'metale kolorowe',
      'kapsle, zakrętki od słoików',
    ],
    notItems: [
      'zmieszanych odpadów komunalnych',
      'plastikowych zabawek',
      'opakowań po lekach i zużytych artykułach medycznych',
      'opakowań po olejach silnikowych, płynach hamulcowych, płynach chłodniczych',
      'części samochodowych, elementów karoserii samochodowej',
      'zużytych baterii i akumulatorów',
      'puszek i pojemników po farbach i lakierach',
      'zużytego sprzętu elektronicznego i AGD',
    ],
  },
  paper: {
    icon: faNewspaper,
    color: 'blue',
    title: 'Papier',
    items: [
      'opakowania z papieru, karton, tekturę (także falistą)',
      'gazety i czasopisma',
      'papier szkolny i biurowy, zadrukowane kartki',
      'zeszyty i książki',
      'papier pakowy',
      'torby i worki papierowe',
    ],
    notItems: [
      'zmieszanych odpadów komunalnych',
      'ręczników papierowych i zużytych chusteczek higienicznych',
      'paragonów',
      'papieru lakierowanego i powleczonego folią',
      'papieru zatłuszczonego lub mocno zabrudzonego',
      'kartonów po mleku i napojach',
      'papierowych worków po nawozach, cemencie i innych materiałach budowlanych',
      'tapet',
      'pieluch jednorazowych i innych materiałów higienicznych',
      'zatłuszczonych jednorazowych opakowań z papieru i naczyń jednorazowych',
      'ubrań',
    ],
  },
  glass: {
    icon: faWineBottle,
    color: 'green',
    title: 'Szkło',
    items: [
      'butelki i słoiki po napojach i żywności (w tym butelki po napojach alkoholowych i olejach roślinnych)',
      'szklane opakowania po kosmetykach (jeżeli nie są wykonane z trwale połączonych kilku surowców)',
    ],
    notItems: [
      'zmieszanych odpadów komunalnych',
      'ceramiki, doniczek, porcelany, fajansu, kryształów',
      'szkła okularowego',
      'szkła żaroodpornego',
      'zniczy z zawartością wosku',
      'żarówek i świetlówek',
      'reflektorów',
      'opakowań po lekach, rozpuszczalnikach, olejach silnikowych',
      'luster',
      'szklanych naczyń kuchennych',
      'szyb okiennych i zbrojonych',
      'monitorów i lamp telewizyjnych',
      'termometrów i strzykawek',
    ],
  },
  mixed: {
    icon: faTrash,
    color: 'gray',
    title: 'Odpady zmieszane',
    items: ['Wszystko to, czego nie można odzyskać w procesie recyklingu'],
    notItems: [
      'odpadów segregowanych',
      'odpadów niebezpiecznych tj. zużytych baterii i akumulatorów, przeterminowanych leków, zużytych świetlówek, opakowań po środkach chemicznych, zużytego sprzętu RTV i AGD (tzw. elektroodpadów)',
      'gruzu budowlanego i innych odpadów remontowych',
      'odpadów wielkogabarytowych',
    ],
  },
  biodegradable: {
    icon: faLeaf,
    color: 'brown',
    title: 'Odpady biodegradowalne',
    items: [
      'odpadki warzywne i owocowe (w tym obierki itp.)',
      'gałęzie drzew i krzewów',
      'skoszoną trawę, liście, kwiaty',
      'trociny i korę drzew',
      'niezaimpregnowane drewno',
      'resztki jedzenia',
    ],
    notItems: [
      'zmieszanych odpadów komunalnych',
      'kości zwierząt',
      'oleju jadalnego',
      'odchodów zwierząt',
      'popiołu z węgla kamiennego',
      'leków',
      'drewna impregnowanego',
      'płyt wiórowych i pilśniowych',
      'opakowań z ziemią i kamieniami',
    ],
  },
  textiles: {
    icon: faTshirt,
    color: 'purple',
    title: 'Tekstylia',
    items: [
      'czyste i suche ubrania',
      'pościel',
      'ręczniki',
      'obrusy',
      'firanki',
      'buty (w parach)',
    ],
    notItems: [
      'zmieszanych odpadów komunalnych',
      'ubrania mokre lub zabrudzone chemikaliami',
      'materiały budowlane (np. wełna mineralna)',
      'dywany i wykładziny',
      'ubrania skażone chemikaliami',
      'części samochodowe',
    ],
  },
};
