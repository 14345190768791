import React, { useEffect, useState } from 'react';

import DocumentItem from '../DocumentItem/DocumentItem';

import './DocumentList.scss';

interface PDFDocument {
  ID: string;
  guid: string;
  post_title: string;
}

interface PDFData {
  id: number;
  title: { rendered: string };
  documents: { value: PDFDocument[]; rendered: string };
}

interface DocumentListProps {
  categoryId: number;
}

const DocumentList: React.FC<DocumentListProps> = ({ categoryId }) => {
  const [pdfs, setPdfs] = useState<PDFData[]>([]);

  useEffect(() => {
    fetch(
      `https://happy-coding.pl/wp-json/wp/v2/posts?categories=${categoryId}`
    )
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter(
          (post: PDFData) =>
            post.documents &&
            post.documents.value &&
            post.documents.value.length > 0
        );
        setPdfs(filteredData);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [categoryId]);

  return (
    <div className='document-list'>
      <ul>
        {pdfs.map((pdf) => (
          <li key={pdf.id}>
            {pdf.documents.value.map((doc) => (
              <DocumentItem
                key={doc.ID}
                name={pdf.title.rendered}
                downloadLink={doc.guid}
              />
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentList;
