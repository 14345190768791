import React from 'react';
import Text from '../core/Text/Text';
import Icon from '../core/Icon/Icon';
import { useContrast } from '../../context/ContrastContext';
import './EmergencyNumbers.scss';
import { getEmergencyNumbers } from './data';
import classNames from 'classnames';

const EmergencyNumbers: React.FC = () => {
  const { highContrast } = useContrast();

  return (
    <div className='emergency-numbers'>
      <ul>
        {getEmergencyNumbers(highContrast).map((item, index) => (
          <li
            key={index}
            className={classNames('emergency-number', { highContrast })}
          >
            {typeof item.icon === 'string' ? (
              <img src={item.icon} width={20} height={20} alt={item.name} />
            ) : (
              <Icon
                className={classNames('icon', { highContrast })}
                icon={item.icon}
                size='lg'
              />
            )}
            <Text>{item.name}</Text>
            <Text>{item.number}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EmergencyNumbers;
