import React from 'react';
import './ContactInfoMain.scss';
import Text from '../core/Text/Text';
import WorkingHours from '../core/WorkingHours/WorkingHours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faBuilding,
  faBank,
} from '@fortawesome/free-solid-svg-icons';
import GoogleMapProvider from '../core/GoogleMapProvider/GoogleMapProvider';
import classNames from 'classnames';
import { useContrast } from '../../context/ContrastContext';

const workingHours_KASA = [
  { day: 'Poniedziałek', hours: '7:15 - 14:15' },
  { day: 'Wtorek', hours: '7:15 - 14:15' },
  { day: 'Środa', hours: '9:15 - 16:15' },
  { day: 'Czwartek', hours: '7:15 - 14:15' },
  { day: 'Piątek', hours: '7:15 - 14:15' },
];

const workingHours_ADM = [
  { day: 'Poniedziałek', hours: '7:00 - 15:00' },
  { day: 'Wtorek', hours: '7:00 - 15:00' },
  { day: 'Środa', hours: '7:00 - 17:00' },
  { day: 'Czwartek', hours: '7:00 - 15:00' },
  { day: 'Piątek', hours: '7:00 - 15:00' },
];

const ContactInfoMain: React.FC = () => {
  const { highContrast } = useContrast();
  const coordinates = { lat: 50.27652616072603, lng: 19.589963754676848 };

  return (
    <div className='contact-info-main-root'>
      <div className='contact-info'>
        <div className={classNames('left-column', { highContrast })}>
          <div className='contact-section'>
            <div className='contact-heading'>
              <FontAwesomeIcon
                className={classNames('icon', { highContrast })}
                icon={faBuilding}
              />
              <Text isBold>Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu</Text>
            </div>
            <Text>ul. Gen. Stefana Buchowieckiego 37</Text>
            <Text>32-300 Olkusz</Text>
            <Text>
              <strong>KRS:</strong> 0000170521
            </Text>
            <Text>
              <strong>NIP:</strong> 6370101943
            </Text>
          </div>
          <div className='contact-section'>
            <div className='contact-heading'>
              <FontAwesomeIcon
                className={classNames('icon', { highContrast })}
                icon={faPhone}
              />
              <Text isBold>Telefony</Text>
            </div>
            <Text>32/ 643 37 98</Text>
            <Text>32/ 647 03 10</Text>
            <Text>32/ 647 03 11</Text>
            <Text>32/ 647 03 12</Text>
            <Text>Dział Techniczny wewn. 24 i 25</Text>
            <Text>Zgłaszanie awarii po godz. 15:00: 508 338 495</Text>
          </div>
          <div className='contact-section'>
            <div className='contact-heading'>
              <FontAwesomeIcon
                className={classNames('icon', { highContrast })}
                icon={faEnvelope}
              />
              <Text isBold>Adres e-mail</Text>
            </div>
            <a href='mailto:sekretariat@sm-nowa.olkusz.pl'>
              <Text> sekretariat@sm-nowa.olkusz.pl</Text>
            </a>
          </div>
          <div className='contact-section'>
            <div className='contact-heading'>
              <FontAwesomeIcon
                className={classNames('icon', { highContrast })}
                icon={faBank}
              />
              <Text isBold>Konto bankowe</Text>
            </div>
            <Text>PKO BP SA O/Olkusz 08 1020 2430 0000 8102 0000 5611</Text>
          </div>
        </div>
        <div className='right-column'>
          <GoogleMapProvider lat={coordinates.lat} lng={coordinates.lng} />
        </div>
      </div>
      <div className='opening-hours'>
        <div className='contact-section'>
          <WorkingHours
            hours={workingHours_ADM}
            title='Godziny pracy administracji'
          />
        </div>
        <div className='contact-section'>
          <WorkingHours hours={workingHours_KASA} title='Kasa czynna' />
          <Text marginTop={16}>
            Przerwa codziennie w godzinach 12:00 - 13:00
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoMain;
