import React from 'react';

import ContactInfoMain from '../components/ContactInfoMain/ContactInfoMain';
import Layout from '../components/Layout/Layout';
import Subheader from '../components/Subheader/Subheader';

const Contact: React.FC = () => {
  return (
    <Layout>
      <Subheader title='Dane teleadresowe' />
      <ContactInfoMain />
    </Layout>
  );
};

export default Contact;
