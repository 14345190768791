import React from 'react';

import Layout from '../components/Layout/Layout';
import Subheader from '../components/Subheader/Subheader';
import PetResponsibilityInfo from '../components/PetResponsibilityInfo/PetResponsibilityInfo';

const Pets: React.FC = () => {
  return (
    <Layout>
      <Subheader title='Domowi pupile. Krótki przewodnik.' />
      <PetResponsibilityInfo />
    </Layout>
  );
};

export default Pets;
