import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';

interface ContrastContextType {
  highContrast: boolean;
  setHighContrast: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContrastContext = createContext<ContrastContextType | null>(null);

export const ContrastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [highContrast, setHighContrast] = useState<boolean>(() => {
    const savedContrast = localStorage.getItem('high-contrast');
    return savedContrast ? JSON.parse(savedContrast) : false;
  });

  useEffect(() => {
    if (highContrast) {
      document.documentElement.classList.add('high-contrast');
    } else {
      document.documentElement.classList.remove('high-contrast');
    }
    localStorage.setItem('high-contrast', JSON.stringify(highContrast));
  }, [highContrast]);

  return (
    <ContrastContext.Provider value={{ highContrast, setHighContrast }}>
      {children}
    </ContrastContext.Provider>
  );
};

export const useContrast = () => {
  const context = useContext(ContrastContext);
  if (!context) {
    throw new Error('useContrast must be used within a ContrastProvider');
  }
  return context;
};
