import React from 'react';

import WarmInfo from '../components/WarmInfo/WarmInfo';
import Layout from '../components/Layout/Layout';
import Subheader from '../components/Subheader/Subheader';

const Warm: React.FC = () => {
  return (
    <Layout>
      <Subheader title='Jednym słowem - ciepło. Krótki przewodnik.' />
      <WarmInfo />
    </Layout>
  );
};

export default Warm;
