import React, { FC } from 'react';
import classNames from 'classnames';

import './HtmlText.scss';
import { useContrast } from '../../../context/ContrastContext';

interface HtmlTextProps {
  as?: keyof JSX.IntrinsicElements;
  fontSize?: string | number;
  isBold?: boolean;
  marginTop?: string | number;
  marginBottom?: string | number;
  className?: string;
  dangerouslySetInnerHTML: { __html: string };
}

const HtmlText: FC<HtmlTextProps> = ({
  as: Tag = 'p',
  fontSize = '1rem',
  isBold = false,
  marginTop = 0,
  marginBottom = 0,
  className = '',
  dangerouslySetInnerHTML,
}) => {
  const { highContrast } = useContrast();

  const fontSizeValue =
    typeof fontSize === 'string' ? parseFloat(fontSize) : fontSize;
  const computedLineHeight = fontSizeValue * 1.75;

  const style = {
    lineHeight: `${computedLineHeight}rem`,
    fontSize,
    fontWeight: isBold ? 'bold' : 'normal',
    marginTop,
    marginBottom,
  };

  return (
    <Tag
      className={classNames('html-text', {
        [className]: !!className,
        highContrast,
      })}
      style={style}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    />
  );
};

export default HtmlText;
