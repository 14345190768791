import React from 'react';
import './Member.scss';
import classNames from 'classnames';

import Text from '../core/Text/Text';
import { useContrast } from '../../context/ContrastContext';

interface MemberProps {
  name: string;
  title: string;
}

const Member: React.FC<MemberProps> = ({ name, title }) => {
  const { highContrast } = useContrast();

  return (
    <div className='member'>
      <Text className={classNames('member-text', { highContrast })}>
        {title}
      </Text>
      <Text
        className={classNames('member-text', { highContrast })}
        fontSize='1.25rem'
      >
        {name}
      </Text>
    </div>
  );
};

export default Member;
