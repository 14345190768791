import React from 'react';

import Layout from '../components/Layout/Layout';
import MarkedPosts from '../components/MarkedPosts/MarkedPosts';
import Posts from '../components/Posts/Posts';
import SocialAndEdu from '../components/SocialAndEdu/SocialAndEdu';
import Subheader from '../components/Subheader/Subheader';
import HeroImages from '../components/HeroImages/HeroImages';
import './Home.scss';

const Home: React.FC = () => {
  return (
    <Layout>
      <section className='hero'>
        <HeroImages />
      </section>
      <section className='home-content'>
        <div id='news'>
          <Subheader title='Najnowsze ogłoszenia' />
        </div>
        <Posts limit={6} categoryId={23} />
        <Subheader title='W trosce o wspólne dobro' />
        <MarkedPosts />
        <Subheader title='Działalność społeczno-wychowawcza' />
        <SocialAndEdu />
      </section>
    </Layout>
  );
};

export default Home;
