import React from 'react';
import './Reminder.scss';
import Text from '../core/Text/Text';

const Reminder: React.FC = () => {
  return (
    <div className='reminder-container'>
      <Text isBold>Dodatkowo:</Text>
      <ul>
        <li>
          <Text>
            Odpady segregowane (w tym gabaryty) oraz wymienione odpady
            niebezpieczne, elektroodpady (w przypadku zakupu nowego sprzętu —
            sprzedawca ma obowiązek przyjąć zużyty) oraz odpady budowlane (w
            ilości do 1 tony na mieszkańca/rok) można oddać w ramach wnoszonej
            opłaty za gospodarowanie odpadami komunalnymi w Gminnym Punkcie
            Selektywnego Zbierania Odpadów Komunalnych w Olkuszu, Al. 1000-lecia
            150. Regulamin GPSZOK wraz z wykazem przyjmowanych odpadów znajduje
            się na stronie internetowej Urzędu Miasta i Gminy w Olkuszu.
          </Text>
        </li>
        <li>
          <Text>
            Przeterminowane leki można oddać do wyznaczonych aptek w Olkuszu, a
            zużyte baterie i akumulatory małogabarytowe do pojemników
            zlokalizowanych na terenie Urzędu Miasta i Gminy w Olkuszu, ul.
            Rynek 1.
          </Text>
        </li>
        <li>
          <Text>
            Miejsca gromadzenia odpadów muszą być utrzymywane przez właściciela
            bądź zarządcę nieruchomości w odpowiednim stanie technicznym,
            porządkowym i sanitarnym. Miejsca gromadzenia odpadów znajdujące się
            na terenie zabudowy wielorodzinnej przyporządkowane są do
            konkretnych nieruchomości i korzystać mogą z nich WYŁĄCZNIE
            mieszkańcy tych nieruchomości. Podrzucanie odpadów do pojemników
            znajdujących się na terenie zabudowy wielorodzinnej przez osoby
            trzecie jest zabronione, a ich podrzucanie w miejsca niedozwolone
            skutkuje konsekwencjami prawnymi!
          </Text>
        </li>
        <li>
          <Text>
            Wystawianie do odbioru odpadów wielkogabarytowych, odpadów
            elektrycznych, elektronicznych oraz zużytych opon, poza terminami
            zbiórek tych odpadów, stanowi wykroczenie!
          </Text>
        </li>
        <li>
          <Text>
            Systemem gospodarowania odpadami objęte są wszystkie odpady
            komunalne wytwarzane w gospodarstwach domowych. Odbiór odpadów (np.
            części samochodowe, akumulatory, opony samochodów ciężarowych, gruz
            powyżej 1 tony, wytwarzanych w wyniku prowadzenia działalności
            gospodarczej) wymaga zawarcia stosownej umowy, a wystawianie ich w
            miejscach gromadzenia odpadów komunalnych stanowi wykroczenie!
          </Text>
        </li>
        <li>
          <Text>
            W przypadku stwierdzenia prowadzenia nieprawidłowej selekcji, opłata
            za gospodarowanie odpadami komunalnymi zostanie podwyższona. W
            przypadku zabudowy wielorodzinnej podwyższona opłata za
            nieprawidłową selekcję dotyczyć będzie WSZYSTKICH mieszkańców danej
            nieruchomości. Dbajmy razem, aby gospodarowanie odpadami w naszej
            Gminie było prowadzone w sposób prawidłowy - w trosce o nasze
            środowisko i finanse!
          </Text>
        </li>
      </ul>
    </div>
  );
};

export default Reminder;
