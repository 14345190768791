import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IconProps {
  icon: IconDefinition;
  size?:
    | 'xs'
    | 'lg'
    | 'sm'
    | '1x'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x';
  color?: string;
  className?: string;
}

const Icon: React.FC<IconProps> = ({
  className,
  icon,
  size = '1x',
  color = 'inherit',
}) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      size={size}
      color={color}
      className={className}
    />
  );
};

export default Icon;
