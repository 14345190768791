import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { ContrastProvider } from './context/ContrastContext';

import SiteRoutes from './SiteRoutes';
library.add(fas);

const App: React.FC = () => {
  return (
    <ContrastProvider>
      <Router>
        <ScrollToTop />
        <SiteRoutes />
      </Router>
    </ContrastProvider>
  );
};

export default App;
