import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import classNames from 'classnames';

import './Error.scss';
import { useContrast } from '../../../context/ContrastContext';

const Error: React.FC<{ message: string }> = ({ message }) => {
  const { highContrast } = useContrast();

  return (
    <div className='error-container'>
      <Icon icon={faExclamationTriangle} color='var(--spol-error)' />
      <Text className={classNames('error-message', { highContrast })}>
        {message}
      </Text>
    </div>
  );
};

export default Error;
