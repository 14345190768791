import {
  faCalculator,
  faChildren,
  faDumbbell,
  faFilm,
  faGift,
  faGraduationCap,
  faHeart,
  faHeartbeat,
  faKeyboard,
  faLanguage,
  faMask,
  faMicrophone,
  faMusic,
  faPalette,
  faPersonBooth,
  faPersonWalking,
  faRunning,
  faScissors,
  faSpa,
  faStar,
  faSuitcase,
  faSwimmer,
  faTabletAlt,
  faTheaterMasks,
  faTree,
  faUserMd,
  faUsers,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import classNames from 'classnames';

import flika from '../../assets/images/ks-flika.png';
import kubus from '../../assets/images/kubus.png';
import { Paths } from '../../constants/paths';
import Icon from '../core/Icon/Icon';
import LinkButton from '../core/LinkButton/LinkButton';
import Text from '../core/Text/Text';

import './SocialAndEdu.scss';
import { useContrast } from '../../context/ContrastContext';

const offers = [
  { icon: faRunning, text: 'zajęcia dance-aerobic' },
  { icon: faMusic, text: 'zajęcia taneczno-rytmiczne' },
  { icon: faHeartbeat, text: 'zdrowy kręgosłup' },
  { icon: faMicrophone, text: 'zajęcia wokalne z emisją głosu' },
  { icon: faKeyboard, text: 'zajęcia gry na pianinie' },
  { icon: faPalette, text: 'plastyka' },
  { icon: faLanguage, text: 'język angielski' },
  { icon: faCalculator, text: 'korepetycje z matematyki' },
  { icon: faDumbbell, text: 'trening interwałowy' },
  { icon: faPersonBooth, text: 'zumba' },
  { icon: faScissors, text: 'amatorska szkoła szycia' },
  { icon: faPersonWalking, text: 'fitness 50 +, fitness 60+' },
  { icon: faTheaterMasks, text: 'Sztukakademia' },
  { icon: faSpa, text: 'zajęcia z kosmetyczką' },
  { icon: faUtensils, text: 'zajęcia z gotowania' },
  { icon: faTabletAlt, text: 'gadżety elektroniczne' },
  { icon: faSwimmer, text: 'zajęcia na basenie' },
  { icon: faUserMd, text: 'konsultacje z psychologiem' },
  { icon: faGraduationCap, text: 'pomoc w nauce' },
];

const events = [
  { icon: faMask, text: 'Bal karnawałowy' },
  { icon: faHeart, text: 'Walentynki' },
  { icon: faGift, text: 'Dzień kobiet' },
  { icon: faUtensils, text: 'Bańki Mydlane w Lany Poniedziałek' },
  { icon: faStar, text: 'Obchody 3 Maja' },
  { icon: faChildren, text: 'Dzień Smerfa' },
  { icon: faSuitcase, text: 'warsztaty wakacyjne' },
  { icon: faChildren, text: 'półkolonie' },
  { icon: faFilm, text: 'wieczorki filmowe' },
  { icon: faStar, text: 'Andrzejki' },
  { icon: faGift, text: 'Mikołaj' },
  { icon: faTree, text: 'Wigilia' },
  { icon: faStar, text: 'Sylwester' },
];

const SocialAndEdu: React.FC = () => {
  const { highContrast } = useContrast();

  return (
    <div className='social-and-edu'>
      <div className='segment'>
        <img
          src={kubus}
          alt='Klub Kubuś'
          className='segment-image'
          width={200}
          height={173.26}
        />
        <div className='kubus-info'>
          <Text>
            Placówka oświatowa – <strong>Klub Osiedlowy „Kubuś”</strong> przy
            ul. Batalionów Chłopskich 2 i 2a realizuje zadania w zakresie
            działalności społeczno – wychowawczej, kulturalnej oraz sportowo –
            rekreacyjnej
            <strong> dla członków Spółdzielni i ich rodzin.</strong>
          </Text>
          <div className='button-kubus-wrapper'>
            <LinkButton message='Klub Kubuś' to={Paths.RESIDENT} />
          </div>
        </div>
      </div>

      <div className='offers'>
        <div className='offers-title'>
          <Text>
            Realizacja zadań w zakresie powyższej działalności odbywa się
            poprzez <strong>organizację zajęć klubowych stałych</strong> takich
            jak:
          </Text>
        </div>
        <ul className='offers-box'>
          {offers.map((offer, index) => (
            <li className='offer' key={index}>
              <Icon
                className={classNames('icon', { highContrast })}
                icon={offer.icon}
              />
              <Text>{offer.text}</Text>
            </li>
          ))}
        </ul>

        <div className='offer'>
          <Icon
            className={classNames('icon', { highContrast })}
            icon={faUsers}
          />
          <Text>
            W Klubie spotykają się także <strong>dwa kluby seniora</strong>:
            Klub ,,Wiarusi" i Klub ,,Razem"
          </Text>
        </div>
      </div>
      <div className='offers'>
        <div className='offers-title'>
          <Text>
            Organizowane są także{' '}
            <strong>imprezy cykliczne i okazjonalne</strong> adresowanych tak do
            dzieci i młodzieży jak i osób dorosłych, seniorów np.:
          </Text>
        </div>
        <ul className='offers-box'>
          {events.map((event, index) => (
            <li className='offer' key={index}>
              <Icon
                className={classNames('icon', { highContrast })}
                icon={event.icon}
              />
              <Text>{event.text}</Text>
            </li>
          ))}
        </ul>
      </div>
      <div className='segment'>
        <Text>
          Nadrzędnym celem powyższej działalności jest{' '}
          <strong>
            organizowanie wolnego czasu dzieci i młodzieży, dorosłych i seniorów
          </strong>
          . Jest to miejsce do rozwijania swoich zainteresowań. Jest to
          doskonała okazja do kształtowanie społecznie akceptowalnych zachowań i
          więzi koleżeńskich, ale również integrowanie osób dorosłych zwłaszcza
          emerytów i rencistów czy osób samotnych poprzez zajęcia klubowe,
          spotkania towarzyskie czy organizację imprez okolicznościowych
          stosownie do zainteresowań określonych środowisk.
        </Text>
      </div>

      <div className='segment'>
        <img
          src={flika}
          alt='Flika'
          className='segment-image'
          width={200}
          height={200}
        />
        <Text>
          Mając na względzie pobudzenie aktywności kulturalnej, oświatowej i
          sportowo – rekreacyjnej oraz zmotywowania jak największej liczby
          mieszkańców naszej Spółdzielni do działań na rzecz całej społeczności
          spółdzielczej opracowano w roku 2018 nową formułę realizacji tych
          zadań. Działalność społeczno – wychowawczą{' '}
          <strong>
            zlecono Stowarzyszeniu –
            <a
              className={classNames('flika-link', { highContrast })}
              target='_blank'
              rel='noreferrer'
              href='https://www.facebook.com/KlubSportowyFlika/?locale=pl_PL'
            >
              Klub Sportowy „Flika”
            </a>
          </strong>
          co zaowocowało zdecydowanie{' '}
          <strong>bogatszą ofertą zajęć i imprez</strong> oraz spowodowało
          uaktywnienie społeczności osiedla.
        </Text>
      </div>
    </div>
  );
};

export default SocialAndEdu;
