import React from 'react';
import './Pagination.scss';
import classNames from 'classnames';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContrast } from '../../../context/ContrastContext';
type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const { highContrast } = useContrast();

  const handleClick = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  return (
    <div className='pagination'>
      <button
        className={classNames('arrow-button', { highContrast })}
        disabled={currentPage === 1}
        onClick={() => handleClick(currentPage - 1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index}
          className={classNames('page-button', {
            active: currentPage === index + 1,
            highContrast,
          })}
          onClick={() => handleClick(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        className={classNames('arrow-button', { highContrast })}
        disabled={currentPage === totalPages}
        onClick={() => handleClick(currentPage + 1)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

export default Pagination;
