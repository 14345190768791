import React from 'react';
import './PetResponsibilityInfo.scss';
import Text from '../core/Text/Text';

const PetResponsibilityInfo: React.FC = () => {
  return (
    <div className='pet-info-container'>
      <div className='pet-info-section'>
        <Text>
          Bycie odpowiedzialnym właścicielem zwierzęcia to nie tylko
          przyjemność, ale i obowiązki. Dowiedz się, jak zapewnić swojemu
          pupilowi zdrowie i szczęście, przestrzegając kilku prostych zasad.
        </Text>
      </div>
      <div className='pet-info-section'>
        <Text isBold>Zdrowie i Higiena</Text>
        <Text>
          Zadbaj o regularne wizyty u weterynarza, szczepienia i kontrolę stanu
          zdrowia swojego zwierzaka. Dbaj o higienę, regularnie kąpiąc i
          szczotkując swojego pupila.
        </Text>
      </div>
      <div className='pet-info-section'>
        <Text isBold>Odpowiednia Dieta</Text>
        <Text>
          Dostarczaj swojemu zwierzakowi odpowiednio zbilansowanej diety,
          dostosowanej do jego potrzeb. Unikaj przekarmiania i dbaj o stały
          dostęp do świeżej wody.
        </Text>
      </div>
      <div className='pet-info-section'>
        <Text isBold>Aktywność Fizyczna</Text>
        <Text>
          Zwierzęta potrzebują regularnej aktywności fizycznej. Zapewnij swojemu
          pupilowi odpowiednią ilość ruchu poprzez codzienne spacery, zabawy i
          ćwiczenia.
        </Text>
      </div>
      <div className='pet-info-section'>
        <Text isBold>Bezpieczeństwo</Text>
        <Text>
          Zadbaj o bezpieczeństwo swojego zwierzaka, zapewniając mu odpowiednie
          warunki do życia, zarówno w domu, jak i na zewnątrz. Używaj smyczy
          podczas spacerów i dbaj o zabezpieczenia w ogrodzie.
        </Text>
      </div>
      <div className='pet-info-section'>
        <Text isBold>Pamiętaj:</Text>
        <ul>
          <li>
            <Text>Zawsze sprzątaj po swoim zwierzaku podczas spacerów</Text>
          </li>
          <li>
            <Text>
              Zapewnij swojemu pupilowi odpowiednie warunki do snu i odpoczynku
            </Text>
          </li>
          <li>
            <Text>Dbaj o regularne kontrole stanu zdrowia i szczepienia</Text>
          </li>
          <li>
            <Text>
              Zadbaj o odpowiednią socjalizację i szkolenie swojego zwierzaka
            </Text>
          </li>
          <li>
            <Text>Spędzaj czas ze swoim pupilem, budując więź i zaufanie</Text>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PetResponsibilityInfo;
