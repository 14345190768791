import { faCogs, faGavel, faUsers } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import classNames from 'classnames';

import Card from '../core/Card/Card';
import Icon from '../core/Icon/Icon';
import Member from '../Member/Member';
import Posts from '../Posts/Posts';

import './CooperativeBodies.scss';
import { useContrast } from '../../context/ContrastContext';

const CooperativeBodies: React.FC = () => {
  const { highContrast } = useContrast();

  return (
    <div className='cooperative-bodies'>
      <div className='cards-container'>
        <Card
          icon={
            <Icon
              className={classNames('icon', { highContrast })}
              icon={faGavel}
            />
          }
          title='Rada Nadzorcza'
          description='Rada Nadzorcza to organ kontrolno-nadzorczy w spółdzielni mieszkaniowej, wybierany przez Walne Zgromadzenie. Jej podstawowym zadaniem jest nadzór nad działalnością zarządu oraz kontrola finansów spółdzielni. Rada Nadzorcza składa się z członków spółdzielni, którzy są wybierani na określoną kadencję, zazwyczaj trwającą kilka lat.'
          moreText='Obecny skład Rady Nadzorczej'
          additionalContent={
            <div className='members'>
              <Member
                name='Zbigniew Kukieła'
                title='Przewodniczący Rady Nadzorczej'
              />
              <Member
                name='Beata Jackowska'
                title='Zastępca Przewodniczącego Rady Nadzorczej'
              />
              <Member
                name='Teresa Kubiczek'
                title='Sekretarz Rady Nadzorczej'
              />
              <Member
                name='Izabela Witmajer'
                title='Przewodnicząca Komisji Ekonomiczno-Finansowej Rady Nadzorczej'
              />
              <Member
                name='Tadeusz Gołaś'
                title='Przewodniczący Komisji Gospodarczo-Technicznej Rady Nadzorczej'
              />
              <Member
                name='Marek Lisiak'
                title='Zastępca Przewodniczącej Komisji Ekonomiczno-Finansowej RN'
              />
              <Member
                name='Marek Szlęzak'
                title='Sekretarz Komisji Ekonomiczno-Finansowej RN'
              />
              <Member
                name='Roman Podsiadło'
                title='Zastępca Przewodniczącego Komisji Gospodarczo-Technicznej RN'
              />
              <Member
                name='Jerzy Piwowar'
                title='Sekretarz Komisji Gospodarczo-Technicznej RN'
              />
              <Member name='Alina Leńczuk' title='Członek Rady Nadzorczej' />
              <Member name='Waldemar Maj' title='Członek Rady Nadzorczej' />
            </div>
          }
        />
        <Card
          icon={
            <Icon
              className={classNames('icon', { highContrast })}
              icon={faCogs}
            />
          }
          title='Zarząd'
          description='Zarząd jest wykonawczym organem spółdzielni mieszkaniowej, odpowiedzialnym za bieżące zarządzanie jej działalnością. Członkowie zarządu są wybierani przez Radę Nadzorczą i odpowiadają za realizację decyzji Walnego Zgromadzenia oraz uchwał Rady Nadzorczej. Zarząd kieruje pracą spółdzielni, zarządza jej majątkiem, prowadzi księgowość oraz reprezentuje spółdzielnię na zewnątrz.'
          moreText='Obecny skład Zarządu'
          additionalContent={
            <div className='members'>
              <Member name='Michał Tworus' title='Prezes Zarządu' />
              <Member
                name='Tomasz Chwast'
                title='Zastępca Prezesa Zarządu Główny Księgowy'
              />
              <Member name='Kazimierz Latała' title='Członek Zarządu' />
            </div>
          }
        />
        <Card
          icon={
            <Icon
              className={classNames('icon', { highContrast })}
              icon={faUsers}
            />
          }
          title='Walne Zgromadzenie'
          description='Walne Zgromadzenie to najwyższy organ spółdzielni mieszkaniowej, składający się ze wszystkich członków spółdzielni. Jego głównym zadaniem jest podejmowanie kluczowych decyzji dotyczących działalności spółdzielni, takich jak zatwierdzanie rocznych sprawozdań finansowych, uchwalanie zmian w statucie czy wybór członków rady nadzorczej.'
          moreText='Zawiadomienia'
          additionalContent={<Posts singleLine categoryId={10} hasPagination />}
        />
      </div>
    </div>
  );
};

export default CooperativeBodies;
