import React from 'react';
import './ContactInfoClub.scss';

import WorkingHours from '../core/WorkingHours/WorkingHours';
import Icon from '../core/Icon/Icon';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Text from '../core/Text/Text';
import { useContrast } from '../../context/ContrastContext';
import classNames from 'classnames';

const workingHours_KUBUS = [
  { day: 'Poniedziałek', hours: '16:00 - 19:00' },
  { day: 'Wtorek', hours: '16:00 - 21:00' },
  { day: 'Środa', hours: '16:00 - 20:00' },
  { day: 'Czwartek', hours: '16:00 - 21:00' },
  { day: 'Piątek', hours: '16:00 - 18:00' },
];

const ContactInfoClub: React.FC = () => {
  const { highContrast } = useContrast();

  return (
    <div className='contact-info-club'>
      <div className='contact-section'>
        <div className='contact-heading'>
          <Icon
            className={classNames('icon', { highContrast })}
            icon={faPhone}
          />
          <Text isBold>Telefon</Text>
        </div>
        <Text>575-890-696</Text>
      </div>
      <Text marginBottom={48}>
        Zapraszamy do kontaktu telefonicznego z Osiedlowym Klubem KUBUŚ przy
        Spółdzielni Mieszkaniowej „NOWA” w Olkuszu prowadzonym przez Klub
        Sportowy FLIKA w godzinach pracy administracji tj. od 16:00 - 20:00 we
        wtorek, środę i czwartek.
      </Text>
      <WorkingHours
        title='Do osobistego kontaktu z Osiedlowym Klubem Kubuś zapraszamy:'
        hours={workingHours_KUBUS}
      />
    </div>
  );
};

export default ContactInfoClub;
