import React, { FC } from 'react';
import './Text.scss';
import { useContrast } from '../../../context/ContrastContext';
import classNames from 'classnames';

interface TextProps {
  as?: keyof JSX.IntrinsicElements;
  fontSize?: string | number;
  isBold?: boolean;
  marginTop?: string | number;
  marginBottom?: string | number;
  className?: string;
  children: React.ReactNode;
}

const Text: FC<TextProps> = ({
  as: Tag = 'p',
  fontSize = '1rem',
  isBold = false,
  marginTop = 0,
  marginBottom = 0,
  className = '',
  children,
}) => {
  const { highContrast } = useContrast();
  const fontSizeValue =
    typeof fontSize === 'string' ? parseFloat(fontSize) : fontSize;
  const computedLineHeight = fontSizeValue * 1.75;

  const style = {
    lineHeight: `${computedLineHeight}rem`,
    fontSize,
    fontWeight: isBold ? 'bold' : 'normal',
    marginTop,
    marginBottom,
  };

  return (
    <Tag
      className={classNames('text', { [className]: !!className, highContrast })}
      style={style}
    >
      {children}
    </Tag>
  );
};

export default Text;
