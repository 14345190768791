import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from '../../core/Icon/Icon';

import './ScrollToTop.scss';
import { useContrast } from '../../../context/ContrastContext';

const ScrollToTop: React.FC = () => {
  const { highContrast } = useContrast();

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  return (
    <button
      className={classNames('scrollTop', { highContrast })}
      onClick={scrollTop}
      style={{ display: showScroll ? 'flex' : 'none' }}
    >
      <Icon icon={faArrowUp} />
    </button>
  );
};

export default ScrollToTop;
