import React from 'react';

import HtmlText from '../HtmlText/HtmlText';
import LinkButton from '../LinkButton/LinkButton';
import Text from '../Text/Text';
import classNames from 'classnames';

import './PostIntro.scss';
import { useContrast } from '../../../context/ContrastContext';

type PostIntroProps = {
  id: number;
  title: string;
  date: string;
  excerpt?: string;
  imageUrl?: string;
  singleLine: boolean;
};

const PostIntro: React.FC<PostIntroProps> = ({
  id,
  title,
  date,
  excerpt,
  imageUrl,
  singleLine,
}) => {
  const { highContrast } = useContrast();
  const saveScrollPosition = () => {
    localStorage.setItem('scrollPosition', window.scrollY.toString());
  };

  if (singleLine) {
    return (
      <div className={classNames('post-intro', 'singleLine', { highContrast })}>
        <div className='post-top'>
          <Text fontSize='1rem' className='post-date'>
            {new Date(date).toLocaleDateString()}
          </Text>
        </div>
        <div className='post-bottom'>
          <Text isBold className='post-title'>
            {title}
          </Text>
          <div className='post-bottom-button'>
            <LinkButton
              to={`/post/${id}`}
              variant='primary'
              message='Czytaj dalej'
              onClick={saveScrollPosition}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('post-intro', { highContrast })}>
      {imageUrl && (
        <div className='post-image-wrapper'>
          <img src={imageUrl} alt={title} className='post-intro-image' />
        </div>
      )}
      <div className='post-content'>
        <div className='post-top'>
          <Text fontSize='1rem' className='post-date'>
            {new Date(date).toLocaleDateString()}
          </Text>
        </div>
        <div className='post-bottom'>
          <div className='post-content-teaser'>
            <HtmlText
              isBold
              className='post-title'
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />

            {excerpt && (
              <div className='post-excerpt'>
                <HtmlText
                  dangerouslySetInnerHTML={{
                    __html: excerpt,
                  }}
                />
              </div>
            )}
          </div>

          <div className='post-bottom-button'>
            <LinkButton
              to={`/post/${id}`}
              variant='primary'
              message='Czytaj dalej'
              onClick={saveScrollPosition}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostIntro;
