import React, { useEffect, useState } from 'react';

import Layout from '../components/Layout/Layout';
import Posts from '../components/Posts/Posts';
import Subheader from '../components/Subheader/Subheader';
import { addScrollEventListener } from '../utils/handleScrolll';
import { NavItemId } from '../data/navbarData';

const News: React.FC = () => {
  const [visibleSection, setVisibleSection] = useState<string | null>(null);

  useEffect(() => {
    const sections = [
      NavItemId.AKTUALNOSCI,
      NavItemId.PRZETARGI,
      NavItemId.PRACA,
      NavItemId.LOKALE,
    ];
    const removeEventListener = addScrollEventListener(
      sections,
      visibleSection,
      setVisibleSection
    );
    return removeEventListener;
  }, [visibleSection]);

  return (
    <Layout>
      <div id={NavItemId.AKTUALNOSCI}>
        <Subheader title='Aktualności' />
        <Posts categoryId={23} hasPagination />
      </div>
      <div id={NavItemId.PRZETARGI}>
        <Subheader title='Przetargi' />
        <Posts categoryId={29} hasPagination />
      </div>
      <div id={NavItemId.PRACA}>
        <Subheader title='Praca' />
        <Posts categoryId={28} hasPagination />
      </div>
      <div id={NavItemId.LOKALE}>
        <Subheader title='Lokale' />
        <Posts categoryId={30} hasPagination />
      </div>
    </Layout>
  );
};

export default News;
