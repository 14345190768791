import React, { useState } from 'react';
import './Card.scss';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useContrast } from '../../../context/ContrastContext';
import classNames from 'classnames';

interface CardProps {
  icon: React.ReactNode;
  title?: string;
  description: string;
  moreText: string;
  additionalContent?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  icon,
  title,
  description,
  moreText,
  additionalContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { highContrast } = useContrast();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classNames('card', { highContrast })}>
      <div className='card-icon'>{icon}</div>
      <div className='card-content'>
        <Text isBold fontSize='1.5rem' as='h4'>
          {title}
        </Text>
        <Text>{description}</Text>
      </div>
      {moreText && additionalContent && (
        <>
          <div className='card-more' onClick={toggleExpand}>
            <Text isBold>{moreText}</Text>
            <Icon
              className={classNames('icon', { highContrast })}
              icon={isExpanded ? faChevronUp : faChevronDown}
            />
          </div>
          <div
            className={classNames('card-additional-content', {
              expanded: isExpanded,
            })}
          >
            {additionalContent}
          </div>
        </>
      )}
    </div>
  );
};

export default Card;
