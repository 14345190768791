import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Error from '../core/Error/Error';
import HtmlText from '../core/HtmlText/HtmlText';
import Icon from '../core/Icon/Icon';
import Loader from '../core/Loader/Loader';
import Text from '../core/Text/Text';
import DocumentItem from '../DocumentItem/DocumentItem';
import Subheader from '../Subheader/Subheader';

import './SinglePost.scss';
import { useContrast } from '../../context/ContrastContext';

type Post = {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  date: string;
  gallery_photos?: {
    value: Array<{
      guid: string;
    }>;
  };
  documents?: {
    value: Array<{
      ID: string;
      guid: string;
      post_title: string;
    }>;
  };
};

const SinglePost: React.FC = () => {
  const { highContrast } = useContrast();

  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `https://happy-coding.pl/wp-json/wp/v2/posts/${id}`
        );
        setPost(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching post');
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading)
    return (
      <div className='loader-wrapper'>
        <Loader />
      </div>
    );
  if (error)
    return (
      <div className='single-post-container'>
        <Error message='Przepraszamy, nie udało się załadować treści. Spróbuj ponownie później.' />
      </div>
    );

  const onClick = () => {
    navigate(-1);
  };

  const openLightbox = (index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const nextPhoto = () => {
    setPhotoIndex((photoIndex + 1) % (post?.gallery_photos?.value.length || 1));
  };

  const prevPhoto = () => {
    setPhotoIndex(
      (photoIndex + (post?.gallery_photos?.value.length || 1) - 1) %
        (post?.gallery_photos?.value.length || 1)
    );
  };

  return (
    <div className='single-post-container'>
      <button
        className={classNames('back-button', { highContrast })}
        onClick={onClick}
      >
        <Icon icon={faArrowLeft} />
      </button>
      {post && (
        <>
          <Subheader title={post.title.rendered} inPost />
          <HtmlText
            dangerouslySetInnerHTML={{
              __html: post.content.rendered,
            }}
          />

          {post.gallery_photos?.value && post.gallery_photos.value.length > 0 && (
            <>
              <Subheader small title='Galeria' />
              <div className='post-images'>
                {post.gallery_photos.value.map((image, index) => (
                  <figure key={index} onClick={() => openLightbox(index)}>
                    <img
                      src={image.guid}
                      alt={`Galeria ${index + 1}`}
                      className='post-image'
                    />
                  </figure>
                ))}
              </div>
            </>
          )}
          <Text marginTop={24} marginBottom={24} className='post-date'>
            Opublikowano: {new Date(post.date).toLocaleDateString()}
          </Text>
          {post.documents?.value && post.documents.value.length > 0 && (
            <div className='post-documents'>
              <Subheader small title='Załączniki' />
              <ul>
                {post.documents.value.map((doc) => (
                  <DocumentItem
                    key={doc.ID}
                    name={doc.post_title}
                    downloadLink={doc.guid}
                  />
                ))}
              </ul>
            </div>
          )}
        </>
      )}

      {isOpen && post?.gallery_photos?.value && (
        <div className='lightbox'>
          <span className='close' onClick={closeLightbox}>
            &times;
          </span>
          <span className='prev' onClick={prevPhoto}>
            &#10094;
          </span>
          <img
            className='lightbox-content'
            src={post.gallery_photos.value[photoIndex].guid}
            alt=''
          />
          <span className='next' onClick={nextPhoto}>
            &#10095;
          </span>
        </div>
      )}
    </div>
  );
};

export default SinglePost;
