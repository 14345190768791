import React from 'react';
import './Logo.scss';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logoOld.png';
import Text from '../../../core/Text/Text';
import useMediaQuery from '../../../../hooks/useMediaQuery';

const Logo: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <Link to='/' className='navbar-logo'>
      <img
        src={logo}
        alt='Logo Spółdzielnia'
        className='logo-image'
        width={36}
        height={36}
      />
      <Text
        className='logo-text'
        isBold
        fontSize={isMobile ? '0.6rem' : '0.8rem'}
      >
        SM „NOWA”
      </Text>
    </Link>
  );
};

export default Logo;
