import {
  faCircleExclamation,
  faAmbulance,
  faFireExtinguisher,
  faPhone,
  faBolt,
} from '@fortawesome/free-solid-svg-icons';

import badgeBlack from '../../assets/icons/badge-black.svg';
import badgeWhite from '../../assets/icons/badge-white.svg';
import waterTapBlack from '../../assets/icons/water-tap-black.svg';
import waterTapWhite from '../../assets/icons/water-tap-white.svg';
import gasCylinderBlack from '../../assets/icons/gas-cylinder-black.svg';
import gasCylinderWhite from '../../assets/icons/gas-cylinder-white.svg';

export const getEmergencyNumbers = (isHighContrast: boolean) => [
  {
    name: 'Zgłoszenie awarii',
    number: '508 338 495',
    icon: faCircleExclamation,
  },
  { name: 'Numer alarmowy', number: '112', icon: faPhone },
  { name: 'Pogotowie ratunkowe', number: '999', icon: faAmbulance },
  {
    name: 'Policja',
    number: '997',
    icon: isHighContrast ? badgeWhite : badgeBlack,
  },
  { name: 'Straż pożarna', number: '998', icon: faFireExtinguisher },
  {
    name: 'Pogotowie gazowe',
    number: '992',
    icon: isHighContrast ? gasCylinderWhite : gasCylinderBlack,
  },
  { name: 'Pogotowie energetyczne', number: '991', icon: faBolt },
  {
    name: 'Pogotowie wodno-kanalizacyjne',
    number: '994',
    icon: isHighContrast ? waterTapWhite : waterTapBlack,
  },
];
