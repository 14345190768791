export enum Paths {
  HOME = '/',
  NEWS = '/ogloszenia',
  ABOUT = '/o-nas',
  CONTACT = '/kontakt',
  WASTE_SEGREGATION = '/segregacja-odpadow',
  WARM = '/cieplo',
  POST = '/post',
  PETS = '/zwierzeta',
  SECURITY = '/security',
  EMERGENCY = '/numery-alarmowe',
  RESIDENT = '/strefa-mieszkanca',
}
