import React from 'react';

import Layout from '../components/Layout/Layout';
import Reminder from '../components/Reminder/Reminder';
import Subheader from '../components/Subheader/Subheader';
import Text from '../components/core/Text/Text';
import WasteSegregationRules from '../components/WasteSegregationRules/WasteSegregationRules';
import './WasteSegregation.scss';

const WasteSegregation: React.FC = () => {
  return (
    <Layout>
      <Subheader title='Jak prowadzić odpowiedzialną gospodarkę odpadami?' />
      <div className='waste-info-section'>
        <Text>
          Segregowanie odpadów pozwala na efektywne wykorzystanie surowców, co
          zmniejsza ilość odpadów trafiających na składowiska i chroni
          środowisko naturalne. Dzięki segregacji, możliwe jest przetwarzanie i
          ponowne wykorzystanie materiałów, co przyczynia się do oszczędności
          zasobów oraz energii. Dodatkowo, segregacja odpadów pomaga zmniejszyć
          emisję gazów cieplarnianych, co ma pozytywny wpływ na walkę ze
          zmianami klimatycznymi.
        </Text>
      </div>
      <WasteSegregationRules type='metals' />
      <WasteSegregationRules type='paper' />
      <WasteSegregationRules type='glass' />
      <WasteSegregationRules type='textiles' />
      <WasteSegregationRules type='mixed' />
      <WasteSegregationRules type='biodegradable' />
      <Reminder />
    </Layout>
  );
};

export default WasteSegregation;
