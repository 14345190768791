import React from 'react';

import EmergencyNumbers from '../components/EmergencyNumbers/EmergencyNumbers';
import Layout from '../components/Layout/Layout';
import Subheader from '../components/Subheader/Subheader';

const Contact: React.FC = () => {
  return (
    <Layout>
      <Subheader title='Numery alarmowe' />
      <EmergencyNumbers />
    </Layout>
  );
};

export default Contact;
