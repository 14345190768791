import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import './Posts.scss';
import Loader from '../core/Loader/Loader';
import Error from '../core/Error/Error';
import Info from '../core/Info/Info';

import PostIntro from '../core/PostIntro/PostIntro';
import Pagination from '../core/Pagination/Pagination';
import { scrollToElementWithOffset } from '../../utils/scrollToElementWithOffset';

type Post = {
  id: number;
  title: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  date: string;
  link: string;
  _embedded: {
    'wp:featuredmedia'?: [
      {
        source_url: string;
      }
    ];
  };
};

const Posts: React.FC<{
  categoryId: number;
  singleLine?: boolean;
  hasPagination?: boolean;
  postsPerPage?: number;
  limit?: number;
}> = ({
  categoryId,
  singleLine = false,
  hasPagination = false,
  postsPerPage = 5,
  limit = null,
}) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `https://happy-coding.pl/wp-json/wp/v2/posts?categories=${categoryId}&_embed`
        );
        setPosts(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching posts');
        setLoading(false);
      }
    };

    fetchPosts();
  }, [categoryId]);

  useEffect(() => {
    const scrollPosition = localStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      localStorage.removeItem('scrollPosition');
    }
  }, []);

  useEffect(() => {
    if (limit !== null && hasPagination) {
      console.warn(
        'Both limit and hasPagination props are set. Pagination will be ignored in favor of limit.'
      );
    }
  }, [limit, hasPagination]);

  const totalPages = limit
    ? Math.ceil(Math.min(posts.length, limit) / postsPerPage)
    : Math.ceil(posts.length / postsPerPage);
  const currentPosts = limit
    ? posts.slice(0, limit)
    : posts.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (topRef.current) {
      scrollToElementWithOffset({ selector: 'posts', extraOffset: 150 });
    }
  };

  if (loading)
    return (
      <div className='loader-wrapper'>
        <Loader />
      </div>
    );

  if (error)
    return (
      <div className='posts-container'>
        <Error message='Przepraszamy, coś poszło nie tak.' />
      </div>
    );

  if (!loading && posts.length === 0)
    return (
      <div className='posts-container'>
        <Info message='Brak ogłoszeń w tej kategorii.' />
      </div>
    );

  return (
    <div id='posts' className='posts-container' ref={topRef}>
      {currentPosts.map((post) => (
        <PostIntro
          key={post.id}
          id={post.id}
          title={post.title.rendered}
          date={post.date}
          singleLine={singleLine}
          excerpt={post.excerpt.rendered}
          imageUrl={post._embedded?.['wp:featuredmedia']?.[0]?.source_url || ''}
        />
      ))}
      {hasPagination && !limit && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default Posts;
