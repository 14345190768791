import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useContrast } from '../../../../context/ContrastContext';
import { NavItem, SubMenuItem } from '../../../../data/navbarData';
import Icon from '../../../core/Icon/Icon';
import Text from '../../../core/Text/Text';
import Submenu from '../Submenu/Submenu';

import './NavElement.scss';

interface NavElementProps {
  item: NavItem;
  isMobileMenuOpen: boolean;
  isMobile: boolean;
  openSubMenu: string | null;
  handleOpenSubmenu: (path: string | null) => void;
  onSubmenuItemClick: (subitem: SubMenuItem) => void;
  toggleMobileMenu: () => void;
}

const NavElement: React.FC<NavElementProps> = ({
  item,
  isMobileMenuOpen,
  openSubMenu,
  isMobile,
  onSubmenuItemClick,
  handleOpenSubmenu,
  toggleMobileMenu,
}) => {
  const location = useLocation();
  const { highContrast } = useContrast();

  const onMouseLeave = () => {
    handleOpenSubmenu(null);
  };

  const onMouseEnter = () => {
    if (item.path) {
      handleOpenSubmenu(item.path);
    }
  };

  const onLinkClick = () => {
    if (location.pathname === item.path) {
      return;
    }

    toggleMobileMenu();
    handleOpenSubmenu(null);
  };

  const onStaticItemClick = () => {
    if (item.path) {
      handleOpenSubmenu(item.path);
    }
  };

  return (
    <li
      key={item.path}
      className={classNames('nav-item', {
        open: isMobileMenuOpen,
        active: location.pathname === item.path,
        highContrast,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isMobile && item.subMenu && item.subMenu.length > 0 ? (
        <div className='nav-content-menu' onClick={onStaticItemClick}>
          <Text className='nav-content-menu-text-mobile'>{item.label}</Text>
          <Icon
            icon={openSubMenu === item.path ? faChevronUp : faChevronDown}
          />
        </div>
      ) : (
        <Link
          to={item.path ?? '/'}
          className='nav-content-menu'
          onClick={onLinkClick}
        >
          <Text className='nav-content-menu-text-desktop'>{item.label}</Text>
        </Link>
      )}
      {item.subMenu && item.subMenu.length > 0 && (
        <Submenu
          onSubmenuItemClick={onSubmenuItemClick}
          item={item}
          isActive={location.pathname === item.path}
          openSubMenu={openSubMenu}
        />
      )}
    </li>
  );
};

export default NavElement;
